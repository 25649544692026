<template>
  <div class="cate-orange">
    <!-- title -->
    <h2 v-text="'退会'"/>

    <div class="wrap-overview">
      <h3 v-text="'退会前にご確認ください'"/>

      <ul class="note">
        <li v-text="'【確認事項】'"/>
        <li v-text="'●退会されますと、保有されているポイントは全て失効となります。'"/>
        <li v-text="'●ポイント交換申請中に退会された場合には、退会と同時に申請は無効となります。'"/>
        <li v-text="'●退会されますと、いかなる理由があっても退会の取り消し・復旧はできません。'"/>
        <li v-text="'●退会後一定期間は同一メールアドレスでの再登録はできません。'"/>
        <li v-text="'※退会後もシステムの都合上、メールマガジン等が届く場合があります。'"/>
        <li class="has-indent" v-text="'ご了承ください。'"/>
      </ul>

      <router-link :to="{name: 'Profile.MailSetting'}"
                   class="btn-setting"
                   custom
                   v-slot="{ navigate }">
        <button @click="navigate"
                v-text="'メール配信に関する設定はこちら'"/>
      </router-link>

      <p v-text="'今後のサービス向上のため、よろしければ退会理由を教えてください。(任意)'"/>

      <!-- reason list -->
      <ValidationObserver
        tag="form"
        @submit.prevent="validateBeforeSubmit()"
        ref="observer">
        <div class="list-reason">
          <div class="list-reason__item"
               v-for="item in OPTIONS_DELETE_USER"
               :key="item.id">

            <input v-model="form.leave_reasons"
                   type="checkbox"
                   :id="item.title"
                   :value="item.value">

            <label :for="item.title" v-text="item.title"/>
          </div>

          <TextAreaField v-model="form.other_reasons"
                         field="その他"
                         :rules="(form.leave_reasons.includes(16) ? 'required' : '') + '|max:2000'"
                         :max-length="2000"
                         :disabled="!form.leave_reasons.includes(16)"
                         :rows="4"/>
        </div>

        <!-- action group -->
        <div class="btn_group">

          <!-- action back -->
          <button @click.prevent="openPageNative('Profile.Mypage')"
                       class="btn-back">戻る
          </button>

          <!-- action submit -->
          <button type="submit"
                  class="btn-submit"
                  v-text="'退会する'"
                  :disabled="!form.leave_reasons.length"/>
        </div>
      </ValidationObserver>

    </div>

  </div>
</template>

<script>
import store from '@/store'
import TextAreaField from '@/components/form/TextAreaField'
import { OPTIONS_DELETE_USER } from '@/enum/options'
import { mapActions } from 'vuex'
import Common from '@/mixins/common.mixin'

export default {
  name: 'DeleteUser',

  mixins: [Common],

  components: {
    TextAreaField
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  data () {
    return {
      form: {
        leave_reasons: [],
        other_reasons: ''
      },
      OPTIONS_DELETE_USER
    }
  },

  methods: {
    ...mapActions('user', ['userLeave']),

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.handleSubmit()
      }
    },

    async handleSubmit () {
      if (!this.form.leave_reasons.includes(16)) {
        delete this.form.other_reasons
      }

      const useLeaveResponse = await this.userLeave(this.form)

      if (useLeaveResponse.status === 204) {
        await store.commit('auth/SET_LOGOUT')

        this.$router.push({ name: 'DeleteUserComplete' }).then(() => {
          // send token '' for app set logout after change route
          this.triggerEventApp('CHANGE_TOKEN', {
            token: ''
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: $font-family-title;
  @include font-size(36px);
  text-align: center;
  color: #C66C44;
  font-weight: normal;
  margin-bottom: 120px;
  line-height: 1;
  @media #{$info-phone} {
    font-size: 32px;
    margin-bottom: 94px;
  }
}

.wrap-overview {
  h3 {
    text-align: center;
    @include font-size(36px);
    color: #C64444;
    font-weight: normal;
    margin-bottom: 50px;
    @media #{$info-phone} {
      font-size: 20px;
      margin-bottom: 25px;
    }
  }

  .note {
    margin-bottom: 50px;
    @media #{$info-phone} {
      margin-bottom: 30px;
    }

    li {
      line-height: 2;
      @include font-size(18px);
      @media #{$info-phone} {
        font-size: 18px;
        text-indent: -10px;
        margin-left: 10px;
      }

      &.has-indent {
        text-indent: 10px;
        @media #{$info-phone} {
          text-indent: unset;
        }
      }
    }
  }

  .btn-setting {
    background-color: #C66C44;
    border-radius: 6px;
    max-width: 357px;
    width: 100%;
    height: 51px;
    display: block;
    margin: 0 auto 52px;
    cursor: pointer;
    color: #FFFFFF;
    @include font-size(18px);
    @media #{$info-phone} {
      margin: 0 auto 25px;
    }
  }

  p {
    @include font-size(18px);
    margin-bottom: 48px;
    @media #{$info-phone} {
      margin-bottom: 40px;
    }
  }

  .list-reason {
    background-color: #E7E5DE;
    padding: 45px;
    margin-bottom: 55px;
    @media #{$info-phone} {
      padding: 30px 15px;
      margin-bottom: 35px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      input[type=checkbox] {
        width: 16px;
        height: 16px;
      }

      label {
        @include font-size(18px);
        color: #282828;
        margin-left: 15px;

        @media #{$info-phone} {
          margin-left: 10px;
          width: 90%;
        }
      }
    }
  }

  .btn_group {
    button {
      border-radius: 6px;
      color: #FFFFFF;
      max-width: 360px;
      width: 100%;
      height: 51px;
      display: block;
      margin: 0 auto;
      cursor: pointer;
    }
    .btn-back {
      background-color: #C66C44;
      margin-bottom: 30px;
      @media #{$info-phone} {
        margin-bottom: 25px;
      }

      a {
        @include font-size(18px);
        color: #FFFFFF;
      }
    }
    .btn-submit {
      background-color: #6F8784;
    }
    .btn-submit[disabled] {
      background: #e3e3e3;
      cursor: not-allowed;
    }
  }

}
</style>
